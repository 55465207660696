<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md3>
        <v-card class="elevation-12">
          <v-form ref="form" @submit.prevent="onSubmit">
            <v-card-text>
              <div style="min-height: 60px;">
                  <v-alert
                    class="alert-default"
                    transition="scale-transition"
                    type="error"
                    :value="alertError !== null"
                    >{{ alertError }}</v-alert
                  >
              </div>
              <v-text-field
                name="login"
                v-model="password"
                :label="$t('auth.password')"
                type="password"
                class="mx-2"
                :rules="rules.rulesPassword"
              ></v-text-field>
              <v-text-field
                name="login"
                v-model="passwordConfirm"
                :label="$t('auth.password')"
                class="mx-2"
                type="password"
                :rules="rules.passwordCF"
              ></v-text-field>
              <v-text-field
                name="password"
                class="mx-2"
                v-model="code"
                :rules="rules.required"
                label="コード"
                type="text"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-btn width="100%" type="submit" style="height: 50px" color="primary">{{ $t('buttons.login') }}</v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { mapMutations, mapGetters } from 'vuex'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { checkValidatePassword } from '@/utils/validate'

export default {
  data () {
    return {
      // data:this.$store.user,
      email: this.$store.state.user.emailSave,
      password: '',
      passwordConfirm: '',
      code: '',
      rules: {
        passwordCF: [
          v =>
            this.checkPassword(v) ||
            'パスワードとパスワードの確認が一致しません',
          v => !!v || '必須項目です。',
          v => !v || checkValidatePassword(v) || 'パスワードは8文字以上で、大文字・小文字・数字・記号のうち2種類以上使用する必要があります。'
        ],
        rulesPassword: [
          v => !!v || '必須項目です。',
          v => !v || checkValidatePassword(v) || 'パスワードは8文字以上で、大文字・小文字・数字・記号のうち2種類以上使用する必要があります。'
        ],
        required: [v => !!v || '必須項目です。']
      }
    }
  },
  methods: {
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setPermissionUser',
      'setRoleAdminUser',
      'setAlertError'
    ]),
    // change password
    async onSubmit () {
      if (this.$refs.form.validate()) {
        const formData = {
          email: this.email,
          token: this.$store.state.user.tokenSave,
          password: this.password
        }
        if (await this.changePassword(formData)) {
          this.$router.push({ path: '/' })
        }
      }
    },

    async changePassword (formData) {
      const query = `
            mutation confirmReset(
              $code: String!
              $pw: String!
              $token: String!
            ) {
              confirmResetPasswordStaff(
                code: $code
                password: $pw
                token: $token
              ) {
                accessToken
                refreshToken
                staff {
                  name
                  id
                }
              }
            }
          `
      const variables = {
        pw: this.passwordConfirm,
        code: this.code,
        token: this.$store.state.user.tokenSave
      }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables
        })
        .then(data => {
          window.localStorage.setItem('id', data.data.confirmResetPasswordStaff.staff.id)
          window.localStorage.setItem('name', data.data.confirmResetPasswordStaff.staff.name)
          window.localStorage.setItem('token', data.data.confirmResetPasswordStaff.accessToken)
          window.localStorage.setItem('refreshToken', data.data.confirmResetPasswordStaff.refreshToken)

          // begin set permission,role user current
          const tokenLogin = data.data.confirmResetPasswordStaff.accessToken
          if (tokenLogin) {
            const decodeTokenUser = jwt_decode(tokenLogin)
            this.setRoleAdminUser(decodeTokenUser.admin)
            if (Object.keys(decodeTokenUser).length !== 0) {
              this.setPermissionUser(decodeTokenUser.rolePermissions)
            } else {
              this.setPermissionUser({})
            }
          }
          // end set permission,role user current

          // this.$store.dispatch('LoginByUsername', {
          //   token: data.data.confirmResetPasswordStaff.accessToken,
          //   refreshToken: data.data.confirmResetPasswordStaff.refreshToken
          // })
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp === 'Entered input is not processable') {
            this.setAlertError('入力された入力は処理できません', {
              root: true
            })
          } else {
            this.setAlertError('新しいものを作成できません')
          }
        })
      if (localStorage.getItem('refreshToken')) {
        return true
      } else {
        return false
      }
    },

    // confirm password
    checkPassword (value) {
      if (value.toString() === this.password.toString()) {
        return true
      } else {
        return false
      }
    }
  },

  watch: {
    doneTodosCount (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertSuccess(null)
        }, 2000)
      }
    },
    doneTodosCountError (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertError(null)
        }, 2000)
      }
    }
  },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay']),
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    }
  },

  mounted: async function () {
    if (this.$store.state.user.token) {
      await this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
.v-form .v-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-card {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
